import axios from "axios";
import {
  START_LOADING,
  STOP_LOADING,
  SET_PROJECTS,
  SHOW_DIALOG,
  SET_ATTACHMENTS,
} from "../types";
import { API_VERSION } from "../../util/config";

export const fetchProjects = async (companyId, userId) => async (dispatch) => {
  try {
    let { data: projects } = await axios.get(
      `${API_VERSION}/projects?company_id=${companyId}`
    );
    dispatch({ type: SET_PROJECTS, payload: projects });
    dispatch({ type: STOP_LOADING });
  } catch (err) {
    console.log(err);
  }
};

export const createDeliveryLog = (project, attachments, content) => (
  dispatch
) => {
  dispatch({ type: START_LOADING });
  const formData = new FormData();
  const today = new Date();
  attachments.forEach((attachment) => {
    formData.append("attachments[]", attachment, attachment.name);
  });
  formData.append("delivery_log[contents]", content.join(", \n"));
  formData.append("delivery_log[time_hour]", today.getHours());
  formData.append("delivery_log[time_minute]", today.getMinutes());
  formData.append(
    "delivery_log[date]",
    today
      .getFullYear()
      .toString()
      .concat(
        "-",
        ("0" + (today.getMonth() + 1)).slice(-2),
        "-",
        ("0" + today.getDate()).slice(-2)
      )
  );
  axios
    .post(`${API_VERSION}/projects/${project.id}/delivery_logs`, formData)
    .then((res) => {
      dispatch({ type: STOP_LOADING });
      dispatch({
        type: SHOW_DIALOG,
        payload: {
          title: "Success!",
          body: `POD successfully uploaded to ${project.name}.`,
        },
      });
    })
    .catch((err) => {
      dispatch({ type: STOP_LOADING });
      dispatch({
        type: SHOW_DIALOG,
        payload: {
          title: "Error",
          body: `There was an error, please try again. ${err}.`,
        },
      });
    });
};

export const removeAttachment = (attachments, attachmentToRemove) => (
  dispatch
) => {
  let newAttachments = attachments.filter(
    (a) => a.name !== attachmentToRemove.name
  );
  dispatch({ type: SET_ATTACHMENTS, payload: newAttachments });
};
