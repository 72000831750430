// App reducer types
export const RESET_APP = "RESET_APP";

// Data reducer types
export const SET_PROJECTS = "SET_PROJECTS";
export const ADD_PROJECT = "ADD_PROJECT";
export const SET_PROJECT = "SET_PROJECT";
export const SET_CONTENT = "SET_CONTENT";
export const ADD_CONTENT = "ADD_CONTENT";
export const RESET_CONTENT = "RESET_CONTENT";
export const ADD_ATTACHMENT = "ADD_ATTACHMENT";
export const RESET_ATTACHMENTS = "RESET_ATTACHMENTS";
export const SET_ATTACHMENTS = "SET_ATTACHMENTS";
export const SET_OTHER_SELECTED = "SET_OTHER_SELECTED";
export const SET_OTHER = "SET_OTHER";
export const RESET_OTHER_SELECTED = "RESET_OTHER_SELECTED";
export const RESET_OTHER = "RESET_OTHER";

// User reducer types
export const SET_USER = "SET_USER";

// UI types
export const START_LOADING = "START_LOADING";
export const STOP_LOADING = "STOP_LOADING";
export const SHOW_DIALOG = "SHOW_DIALOG";
export const HIDE_DIALOG = "HIDE_DIALOG";
