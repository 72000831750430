import React, { useEffect, useState } from "react";
import "../styles/requirements.css";

// Components
import Navbar from "../components/Navbar";

// Material UI
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Paper from "@material-ui/core/Paper";

const instructions = [
  "1. Description",
  "2. Number of Units",
  "3. Pieces / Unit",
  "4. Total Pieces",
  "5. OPTIONAL - Total Count",
  "6. OK to Pay Signature",
  "7. Receipt Date",
  "8. Driver's Signature - Required if Discrepancy",
  "9. Comments",
];

function Requirements(props) {
  const [mobile, setMobile] = useState(window.innerWidth > 500 ? false : true);

  useEffect(() => {
    const handleResize = () =>
      window.innerWidth > 500 ? setMobile(false) : setMobile(true);
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [mobile]);

  return (
    <div>
      <Navbar
        title={"POD Requirements"}
        showNavbarBack
        history={props.history}
        back={"upload"}
      />
      <Paper elevation={mobile ? 0 : 5} className="requirementsContainer">
        <Table>
          <TableBody>
            {instructions.map((i, id) => (
              <TableRow key={id}>
                <TableCell>{i}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Paper>
    </div>
  );
}

export default Requirements;
