import React, { useState, useEffect } from "react";
import "../styles/upload.css";

// Redux
import { useSelector, useDispatch } from "react-redux";
import { removeAttachment } from "../redux/actions/dataActions";
import { HIDE_DIALOG, ADD_ATTACHMENT } from "../redux/types";

// MUI
import Typography from "@material-ui/core/Typography";
import Fab from "@material-ui/core/Fab";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import ClearIcon from "@material-ui/icons/Clear";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";

// Components
import Navbar from "../components/Navbar";
import Popup from "../components/Popup";

function Upload(props) {
  const project = useSelector((state) => state.data.project);
  const attachments = useSelector((state) => state.data.attachments);
  const showDialog = useSelector((state) => state.UI.showDialog);
  const dialog = useSelector((state) => state.UI.dialog);
  const [mobile, setMobile] = useState(window.innerWidth > 500 ? false : true);
  const dispatch = useDispatch();

  useEffect(() => {
    const handleResize = () =>
      window.innerWidth > 500 ? setMobile(false) : setMobile(true);
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [mobile]);

  return (
    <div>
      <Popup
        open={showDialog}
        title={dialog.title}
        body={dialog.body}
        onClose={() => {
          dispatch({ type: HIDE_DIALOG });
          document.getElementById("attachment").value = null;
        }}
      />
      <Fab
        color="primary"
        aria-label="POD Requirements"
        style={{ position: "absolute", right: "1.5em", bottom: "1.5em" }}
        onClick={() => props.history.push("/requirements")}
      >
        <Typography variant="h6">?</Typography>
      </Fab>
      <Navbar
        title={project.name}
        history={props.history}
        showNavbarBack
        back={"projects"}
        showNavbarNext
        next={"contents"}
        nextReady={attachments.length !== 0 && project !== ""}
      />
      <Paper elevation={mobile ? 0 : 5} className="uploadPODContainer">
        <Table>
          <TableBody>
            <TableRow>
              <TableCell
                onClick={() => {
                  const ref = document.getElementById("attachment");
                  ref.click();
                }}
                className="uploadButton"
              >
                {attachments.length === 0 ? (
                  <CloudUploadIcon fontSize="large" color="primary" />
                ) : (
                  <AddCircleIcon fontSize="large" color="primary" />
                )}

                <Typography display="block" style={{ fontWeight: "bold" }}>
                  {attachments.length === 0 ? "Upload a POD" : "Add a Page"}
                </Typography>
              </TableCell>
            </TableRow>
            {attachments.map((attachment, id) => (
              <TableRow key={id}>
                <TableCell>
                  <div style={{ float: "left" }}>{attachment.name}</div>
                  <ClearIcon
                    style={{ float: "right" }}
                    onClick={() =>
                      dispatch(removeAttachment(attachments, attachment))
                    }
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Paper>

      <input
        type="file"
        id="attachment"
        name="attachment"
        onChange={(e) => {
          var upload = e.target.files[0];
          var today = new Date();
          var newName =
            project.project_number +
            "_" +
            today
              .getFullYear()
              .toString()
              .concat(
                ("0" + (today.getMonth() + 1)).slice(-2),
                ("0" + today.getDate()).slice(-2),
                "-",
                ("0" + today.getHours()).slice(-2),
                ("0" + today.getMinutes()).slice(-2),
                ("0" + today.getSeconds()).slice(-2)
              ) +
            "." +
            upload.name.split(".").pop();
          const fileWithNewName = new File([upload], newName, {
            type: upload.type,
          });
          dispatch({ type: ADD_ATTACHMENT, payload: fileWithNewName });
          document.getElementById("attachment").value = null;
        }}
        hidden
      />
    </div>
  );
}

export default Upload;
