import React from "react";
import { Route, Redirect } from "react-router-dom";
import axios from "axios";

const AuthRoute = ({ component: Component, authenticated, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      axios.defaults.headers.common["Authorization"] !== undefined ? (
        <Component {...props} />
      ) : (
        <Redirect to="/login" />
      )
    }
  />
);

export default AuthRoute;
