import React from "react";
import "./App.css";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import axios from "axios";
import AuthRoute from "./util/AuthRoute";

// Redux
import { Provider } from "react-redux";
import store from "./redux/store";

// MUI
import { ThemeProvider as MuiThemeProvider } from "@material-ui/core/styles";
import createMuiTheme from "@material-ui/core/styles/createMuiTheme";
import themeFile from "./util/theme";

// Components
import login from "./pages/login";
import upload from "./pages/upload";
import projects from "./pages/projects";
import requirements from "./pages/requirements";
import contents from "./pages/contents";
import summary from "./pages/summary";

axios.defaults.baseURL = "https://api.procore.com/rest";

const theme = createMuiTheme(themeFile);

function App() {
  return (
    <MuiThemeProvider theme={theme}>
      <Provider store={store}>
        <Router>
          <div className="container">
            <Switch>
              <Route exact path="/login" component={login} />
              <AuthRoute exact path="/upload" component={upload} />
              <AuthRoute exact path="/projects" component={projects} />
              <AuthRoute exact path="/requirements" component={requirements} />
              <AuthRoute exact path="/contents" component={contents} />
              <AuthRoute exact path="/" component={summary} />
            </Switch>
          </div>
        </Router>
      </Provider>
    </MuiThemeProvider>
  );
}

export default App;
