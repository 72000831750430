import React, { useEffect, useState, useRef } from "react";
import "../styles/contents.css";

// Redux
import { SET_CONTENT, SET_OTHER, SET_OTHER_SELECTED } from "../redux/types";
import { useDispatch, useSelector } from "react-redux";

// Components
import Navbar from "../components/Navbar";

// Material UI
import Typography from "@material-ui/core/Typography";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Paper from "@material-ui/core/Paper";
import Checkbox from "@material-ui/core/Checkbox";
import InputBase from "@material-ui/core/InputBase";

const contents = [
  "Glue / Caulk",
  "Hardware - Loose",
  "Hardware - Tiedown",
  "Header Stock",
  "LVL / PSL Beam",
  "Nails / Fasteners",
  "OSB / Plywood",
  "OSB T&G",
  "Plate Stock",
  "PT Lumber",
  "Studs",
  "Small Tools / Safety",
  "Trusses - Floor",
  "Trusses - Roof",
  "Wrap / Tape",
];

function Contents(props) {
  const dispatch = useDispatch();
  const [mobile, setMobile] = useState(window.innerWidth > 600 ? false : true);
  const content = useSelector((state) => state.data.content);
  const project = useSelector((state) => state.data.project);
  const other = useSelector((state) => state.data.other);
  const otherSelected = useSelector((state) => state.data.otherSelected);
  const otherRef = useRef(null);

  useEffect(() => {
    const handleResize = () =>
      window.innerWidth > 500 ? setMobile(false) : setMobile(true);
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [mobile]);

  let handleClick = (event, selected) => {
    const selectedIndex = content.indexOf(selected);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(content, selected);
    } else {
      newSelected = content.filter((e) => e !== selected);
    }
    dispatch({ type: SET_CONTENT, payload: newSelected });
  };

  let isSelected = (item) => content.indexOf(item) !== -1;

  return (
    <div>
      <Navbar
        title={project.name}
        history={props.history}
        showNavbarBack
        back={"upload"}
        showNavbarNext
        next={""}
        nextReady={content.length !== 0 || otherSelected}
      />
      <Paper elevation={mobile ? 0 : 5} className="contentsContainer">
        <Table>
          <TableHead>
            <TableRow>
              <TableCell colSpan={2}>
                <Typography variant="h6">Contents:</Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {contents.sort().map((contentItem, id) => {
              const isItemSelected = isSelected(contentItem);
              return (
                <TableRow
                  key={id}
                  onClick={(event) => {
                    handleClick(event, contentItem);
                  }}
                >
                  <TableCell padding="checkbox">
                    <Checkbox checked={isItemSelected} />
                  </TableCell>
                  <TableCell>{contentItem}</TableCell>
                </TableRow>
              );
            })}
            <TableRow onClick={() => otherRef.current.focus()}>
              <TableCell padding="checkbox">
                <Checkbox checked={otherSelected} disableRipple />
              </TableCell>
              <TableCell>
                <InputBase
                  inputRef={otherRef}
                  placeholder="Other..."
                  value={other}
                  onChange={(event) => {
                    event.target.value === ""
                      ? dispatch({ type: SET_OTHER_SELECTED, payload: false })
                      : dispatch({ type: SET_OTHER_SELECTED, payload: true });
                    dispatch({ type: SET_OTHER, payload: event.target.value });
                  }}
                  fullWidth
                  size="small"
                  style={{
                    fontFamily: "inherit",
                    fontSize: "inherit",
                  }}
                />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Paper>
    </div>
  );
}

export default Contents;
