import {
  START_LOADING,
  STOP_LOADING,
  SHOW_DIALOG,
  HIDE_DIALOG,
} from "../types";
const initialState = {
  loading: false,
  showDialog: false,
  dialog: {
    title: "Uh-oh",
    body: "Something didn't work correctly. Please try again.",
  },
};

export default function (state = initialState, action) {
  switch (action.type) {
    case START_LOADING:
      return {
        ...state,
        loading: true,
      };
    case STOP_LOADING:
      return {
        ...state,
        loading: false,
      };
    case SHOW_DIALOG:
      return {
        ...state,
        showDialog: true,
        dialog: {
          title: action.payload.title,
          body: action.payload.body,
        },
      };
    case HIDE_DIALOG:
      return {
        ...state,
        showDialog: false,
        dialog: initialState.dialog,
      };
    default:
      return state;
  }
}
