import {
  SET_PROJECTS,
  ADD_PROJECT,
  SET_PROJECT,
  SET_CONTENT,
  RESET_CONTENT,
  ADD_ATTACHMENT,
  RESET_ATTACHMENTS,
  SET_ATTACHMENTS,
  SET_OTHER_SELECTED,
  SET_OTHER,
  RESET_OTHER_SELECTED,
  RESET_OTHER,
  ADD_CONTENT,
} from "../types";
const initialState = {
  projects: [],
  project: null,
  content: [],
  attachments: [],
  otherSelected: false,
  other: "",
};

export default function (state = initialState, action) {
  switch (action.type) {
    case ADD_PROJECT:
      return {
        ...state,
        projects: [...state.projects, action.payload],
      };
    case SET_PROJECTS:
      return {
        ...state,
        projects: action.payload,
      };
    case SET_PROJECT:
      return {
        ...state,
        project: action.payload,
      };
    case SET_CONTENT:
      return {
        ...state,
        content: action.payload,
      };
    case ADD_CONTENT:
      return {
        ...state,
        content: [...state.content, action.payload],
      };
    case RESET_CONTENT:
      return {
        ...state,
        content: initialState.content,
      };
    case SET_ATTACHMENTS:
      return {
        ...state,
        attachments: action.payload,
      };
    case ADD_ATTACHMENT:
      return {
        ...state,
        attachments: [...state.attachments, action.payload],
      };
    case RESET_ATTACHMENTS:
      return {
        ...state,
        attachments: initialState.attachments,
      };
    case SET_OTHER_SELECTED:
      return {
        ...state,
        otherSelected: action.payload,
      };
    case RESET_OTHER_SELECTED:
      return {
        ...state,
        otherSelected: initialState.otherSelected,
      };
    case SET_OTHER:
      return {
        ...state,
        other: action.payload,
      };
    case RESET_OTHER:
      return {
        ...state,
        other: initialState.other,
      };
    default:
      return state;
  }
}
