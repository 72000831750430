import React, { useState, useEffect } from "react";
import "../styles/summary.css";

// Redux
import { useSelector, useDispatch } from "react-redux";
import {
  HIDE_DIALOG,
  RESET_CONTENT,
  RESET_ATTACHMENTS,
  RESET_OTHER,
  RESET_OTHER_SELECTED,
} from "../redux/types";
import { createDeliveryLog } from "../redux/actions/dataActions";

// MUI
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import CircularProgress from "@material-ui/core/CircularProgress";

// Components
import Navbar from "../components/Navbar";
import Popup from "../components/Popup";

function Summary(props) {
  const dispatch = useDispatch();
  const [mobile, setMobile] = useState(window.innerWidth > 500 ? false : true);
  const attachments = useSelector((state) => state.data.attachments);
  const content = useSelector((state) => state.data.content);
  const project = useSelector((state) => state.data.project);
  const dialog = useSelector((state) => state.UI.dialog);
  const showDialog = useSelector((state) => state.UI.showDialog);
  const loading = useSelector((state) => state.UI.loading);

  useEffect(() => {
    const handleResize = () =>
      window.innerWidth > 500 ? setMobile(false) : setMobile(true);
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [mobile]);

  let onSubmit = () => {
    dispatch(createDeliveryLog(project, attachments, content));
  };

  return (
    <div className="summaryContainer">
      {loading && (
        <CircularProgress
          color="primary"
          style={{
            zIndex: 10,
            margin: "25em auto",
            display: "block",
            justifyContent: "center",
          }}
        />
      )}
      <Popup
        open={showDialog}
        title={dialog.title}
        body={dialog.body}
        onClose={() => {
          dispatch({ type: HIDE_DIALOG });
          dispatch({ type: RESET_CONTENT });
          dispatch({ type: RESET_ATTACHMENTS });
          dispatch({ type: RESET_OTHER });
          dispatch({ type: RESET_OTHER_SELECTED });
          props.history.push("/upload");
        }}
      />
      <Navbar
        title={project.name}
        history={props.history}
        showNavbarBack
        back={"contents"}
        showNavbarSubmit={!loading}
        onNavbarSubmit={onSubmit}
      />
      {!loading && (
        <div>
          <Paper
            elevation={mobile ? 0 : 5}
            className="summaryContainerAttachments"
          >
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <Typography variant="h6">Pages:</Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {attachments.map((attachment, id) => (
                  <TableRow key={id}>
                    <TableCell>{attachment.name}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Paper>
          <Paper elevation={mobile ? 0 : 5} className="summaryContainerContent">
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <Typography variant="h6">Contents:</Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {content.sort().map((contentItem, id) => (
                  <TableRow key={id}>
                    <TableCell>{contentItem}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Paper>
        </div>
      )}
    </div>
  );
}

export default Summary;
