import React, { useEffect, useState } from "react";
import { COMPANY_ID } from "../util/config";
import "../styles/projects.css";

// Redux
import {
  SET_PROJECT,
  START_LOADING,
  STOP_LOADING,
  RESET_ATTACHMENTS,
  RESET_CONTENT,
  RESET_OTHER,
  RESET_OTHER_SELECTED,
} from "../redux/types";
import { useSelector, useDispatch } from "react-redux";
import { fetchProjects } from "../redux/actions/dataActions";
import { fetchUser } from "../redux/actions/userActions";

// MUI
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import CircularProgress from "@material-ui/core/CircularProgress";
import Paper from "@material-ui/core/Paper";

// Components
import Navbar from "../components/Navbar";

function Projects(props) {
  const [mobile, setMobile] = useState(window.innerWidth > 500 ? false : true);
  const projects = useSelector((state) => state.data.projects);
  const user = useSelector((state) => state.user);
  const loading = useSelector((state) => state.UI.loading);
  const dispatch = useDispatch();

  useEffect(() => {
    const handleResize = () =>
      window.innerWidth > 600 ? setMobile(false) : setMobile(true);
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [mobile]);

  useEffect(() => {
    dispatch({ type: START_LOADING });
    dispatch({ type: RESET_CONTENT });
    dispatch({ type: RESET_ATTACHMENTS });
    dispatch({ type: RESET_OTHER });
    dispatch({ type: RESET_OTHER_SELECTED });

    dispatch(fetchUser());
  }, [dispatch]);

  useEffect(() => {
    if (user.id !== undefined) {
      if (projects.length === 0) {
        dispatch(fetchProjects(COMPANY_ID, user["id"]));
      } else {
        dispatch({ type: STOP_LOADING });
      }
    }
  }, [user, projects, dispatch]);

  return (
    <div>
      {loading && (
        <CircularProgress
          color="primary"
          style={{
            zIndex: 10,
            margin: "25em auto",
            display: "block",
            justifyContent: "center",
          }}
        />
      )}
      <Navbar title={"Projects"} history={props.history} />
      <Paper elevation={mobile ? 0 : 5} className="contentContainer">
        <Table>
          <TableBody>
            {projects.map((project) => (
              <TableRow
                key={project.id}
                onClick={() => {
                  dispatch({ type: SET_PROJECT, payload: project });
                  props.history.push("/upload");
                }}
                hover
              >
                <TableCell>{project.name}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Paper>
    </div>
  );
}

export default Projects;
