import axios from "axios";

const CLIENT_ID =
  "5da5c6adcb4bbeb58a839a61487091eca074e65e8c13960cdeb25c11dfd94591";

const AUTHORIZATION_ENDPOINT = "https://login.procore.com/oauth/authorize";

// const HOSTED = "http://localhost:3000/projects";
const HOSTED = "https://delivery.5starbuild.net/projects";

export const AUTH_URL =
  AUTHORIZATION_ENDPOINT +
  "?response_type=token" +
  "&client_id=" +
  CLIENT_ID +
  "&redirect_uri=" +
  HOSTED;

export function extractToken(hash) {
  var match = hash.match(/access_token=([\w.-]+)/);
  return !!match && match[1];
}

export function extractExpiresIn(hash) {
  var match = hash.match(/expires_in=(\d+)/);
  return !!match && parseInt(match[1], 10);
}

const hash = window.location.hash;
const accessToken = extractToken(hash);
const expiresIn = extractExpiresIn(hash);

if (accessToken && expiresIn) {
  // store the access token and expires in value in local storage or state
  localStorage.setItem("accessToken", accessToken);
  localStorage.setItem("expiresIn", expiresIn);

  // set a timeout to refresh the token
  setTimeout(refreshToken, expiresIn * 1000);
}

function refreshToken() {
  // get the refresh token from local storage or state
  const refreshToken = localStorage.getItem("refreshToken");

  if (refreshToken) {
    // make an API call to get a new access token and expires in value
    axios
      .post("https://login.procore.com/oauth/token", {
        grant_type: "refresh_token",
        refresh_token: refreshToken,
        client_id: CLIENT_ID,
      })
      .then((response) => {
        // update the access token and expires in value in local storage or state
        localStorage.setItem("accessToken", response.data.access_token);
        localStorage.setItem("expiresIn", response.data.expires_in);

        // set a new timeout to refresh the token
        setTimeout(refreshToken, response.data.expires_in * 1000);
      })
      .catch((error) => {
        // handle error
        console.log(error);
      });
  } else {
    // redirect to login page or show an error message
    window.location.href = AUTH_URL;
  }
}

export const COMPANY_ID = "6080";
export const API_VERSION = "/v1.0";
