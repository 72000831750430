import axios from "axios";
import { SET_USER } from "../types";
import { API_VERSION } from "../../util/config";

export const fetchUser = () => (dispatch) => {
  axios
    .get(`${API_VERSION}/me`)
    .then((res) => {
      dispatch({
        type: SET_USER,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
    });
};
