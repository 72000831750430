export default {
  palette: {
    primary: {
      main: "#00a5db",
      contrastText: "#fff"
    },
    secondary: {
      main: "#022b3a",
      contrastText: "#fff"
    }
  },
  typography: {
    useNextVarients: true
  }
};
