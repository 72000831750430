import React from "react";

// Redux
import { useDispatch, useSelector } from "react-redux";
import {
  ADD_CONTENT,
  SET_CONTENT,
  RESET_OTHER,
  RESET_OTHER_SELECTED,
} from "../redux/types";

// MUI
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";

function Navbar(props) {
  const dispatch = useDispatch();
  const other = useSelector((state) => state.data.other);
  const content = useSelector((state) => state.data.content);
  const otherSelected = useSelector((state) => state.data.otherSelected);

  return (
    <AppBar
      elevation={0}
      style={{
        backgroundColor: "#00a5db",
      }}
    >
      <Toolbar
        style={{
          minHeight: 64,
          maxHeight: 64,
        }}
      >
        {props.showNavbarBack && (
          <ArrowBackIosIcon
            style={{ float: "left" }}
            onClick={() => {
              if (otherSelected) {
                let newSelected = [];
                newSelected = content.filter((e) => e !== other);
                dispatch({ type: SET_CONTENT, payload: newSelected });
              }
              props.history.push("/" + props.back);
            }}
          />
        )}
        <Typography
          variant="h6"
          style={{
            margin: "0 auto",
            alignContent: "center",
          }}
        >
          {props.title}
        </Typography>
        {props.showNavbarNext && (
          <Typography
            variant="body1"
            style={{
              float: "right",
              opacity: props.nextReady ? 1 : 0.25,
            }}
            onClick={() => {
              if (otherSelected && props.next === "") {
                if (content.indexOf(other) === -1) {
                  dispatch({ type: ADD_CONTENT, payload: other });
                } else {
                  dispatch({ type: RESET_OTHER });
                  dispatch({ type: RESET_OTHER_SELECTED });
                }
              }
              if (props.nextReady) {
                props.history.push("/" + props.next);
              }
            }}
          >
            Next
          </Typography>
        )}
        {props.showNavbarSubmit && (
          <Typography
            variant="body1"
            style={{
              float: "right",
            }}
            onClick={props.onNavbarSubmit}
          >
            Send
          </Typography>
        )}
      </Toolbar>
    </AppBar>
  );
}

export default Navbar;
